
import "../assets/css/plugins.css"
import "../assets/css/style.css"

import { Header } from "../module/header"
import { Footer } from "../module/footer"

import Banner from "../assets/img/banner.jpg"
import icon from "../assets/img/icon.png"
import steuern from "../assets/img/Steuern.jpg"
import buchhaltung from "../assets/img/Buchhaltung_.jpg"
import treuhad from "../assets/img/Treunhand.jpg"
import { Helmet } from "react-helmet"


function Home() {


    return (
        <>

            <Helmet>
                <link rel="icon" href={"../assets/img/favico.ico"} />
                <meta charSet="utf-8" />
                <title>Multipunkt</title>
                <link rel="canonical" href="https://multipunkt.ch/" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" name="description" content="" />
                <meta property="og:description" name="description" content="" />
            </Helmet>

            <div id="home"></div>
            <div>
                <div className="wrapper banner-head" style={{ backgroundImage: `url(${Banner})` }}>
                    <div className="container text-center">
                        <div className="row banner-text" style={{ position: "relative" }}>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-5 banner-inner">
                                <h1 className="display-6 text-white mb-3 bg-white rounded text-dark p-3 font-md-title-16">
                                    Sie konzentrieren sich auf Ihr Geschäft, wir behalten ein Auge auf die Zahlen
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="uberuns"></div>
            <div className="bg-light">
                <div className="container pt-mb-10 pb-mb-10">
                    <div className="row" style={{ minHeight: "370px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div className="col-lg-12 text-center">
                            <img className="w-auto rounded" src={icon} alt="" style={{ height: "100px" }} />
                            <h3 className="display-5 mb-2 text-multipunk-color mt-2">ÜBER UNS</h3>
                            <p className="font-md-16 text-m-center" style={{ textAlign: "justify" }}>
                                Mit einer mehr als 15-jährigen Erfahrung sind wir ein Sinnbild für buchhalterische Spitzenleistungen. Unser breites Spektrum an buchhalterischen Dienstleistungen ist massgeschneidert für Unternehmen unterschiedlicher Grösse und Komplexität. Wir verpflichten uns dazu, unseren Kunden aussergewöhnliche Dienstleistungen zu bieten, um ihren buchhalterischen Erfolg zu fördern und eine sichere finanzielle Zukunft zu gewährleisten.
                            </p>
                            <p className="font-md-16 text-m-center" style={{ textAlign: "justify" }}>
                                Unser Ziel ist es, unseren Kunden erstklassige Dienstleistungen zur Verfügung zu stellen, um buchhalterische Stabilität und Erfolg zu erreichen. Unser spezialisiertes Team in Finanzangelegenheiten ist darauf spezialisiert, massgeschneiderte Lösungen zu entwickeln, die den individuellen Anforderungen jedes Kunden gerecht werden. Kundenzufriedenheit und Zuverlässigkeit sind die fundamentalen Werte von Multipunkt GmbH.
                                Wir danken Ihnen herzlich dafür, dass Sie uns ausgewählt haben, um Ihre buchhalterische Zukunft zu sichern und Ihre finanziellen Ziele zu erreichen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="steuern"></div>
            <div className="bg-soft-primary">
                <div className="container pt-mb-10 pb-mb-10">
                    <div className="row" style={{ minHeight: "370px", display: "flex", alignItems: "center" }}>
                        <div className="col-12 col-lg-7 text-m-center mb-md-15">
                            <h3 className="display-5 text-multipunk-color">STEUERN </h3>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Allgemeine Steuerberatung und Steuerplanung.</p>
                                </div>
                            </div>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Ausfertigung Steuererklärung für juristische und natürliche Personen.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <figure>
                                <img className="w-auto rounded" src={steuern} alt="" style={{ maxHeight: "340px", width: "100%" }} />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <div id="buchhaltung"></div>
            <div className="bg-light">
                <div className="container pt-mb-10 pb-mb-10">
                    <div className="row" style={{ minHeight: "370px", display: "flex", alignItems: "center" }}>
                        <div className="col-12 col-lg-5 d-md-none">
                            <figure>
                                <img className="w-auto rounded" src={buchhaltung} alt="" style={{ maxHeight: "340px", width: "100%" }} />
                            </figure>
                        </div>
                        <div className="col-12 col-lg-7 text-m-center mb-md-15">
                            <h3 className="display-5 text-multipunk-color">BUCHHALTUNG</h3>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Einrichten und Führen von Kundenbuchhaltungen aller Art.</p>
                                </div>
                            </div>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Jahresabschlussarbeiten.</p>
                                </div>
                            </div>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Erstellung Bilanz und Erfolgsrechnung, Zwischenbilanzen und Geschäftsberichte. </p>
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>MWST- und Sozialversicherungsdeklarationen.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 d-lg-none">
                            <figure>
                                <img className="w-auto rounded" src={buchhaltung} alt="" style={{ maxHeight: "340px", width: "100%" }} />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <div id="treuhad"></div>
            <div className="bg-soft-primary">
                <div className="container pt-mb-10 pb-mb-10">
                    <div className="row" style={{ minHeight: "370px", display: "flex", alignItems: "center" }}>
                        <div className="col-12 col-lg-7 text-m-center mb-md-15">
                            <h3 className="display-5 text-multipunk-color">TREUHAND</h3>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Gründungen und Umwandlungen von Unternehmen.</p>
                                </div>
                            </div>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Sitzverlegungen.</p>
                                </div>
                            </div>
                            <div>
                                <div className="mb-md-10" style={{ fontSize: "18px" }}>
                                    <p className="mb-1 d-flex text-m-center font-md-16"><i className="uil uil-arrow-circle-right me-2 d-md-none"></i>Kapitalerhöhungen.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <figure>
                                <img className="w-auto rounded" src={treuhad} alt="" style={{ height: "auto", width: "100%" }} />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Home;