import Logo from "../assets/img/white-colo.png"


export function Footer() {

    return (
        <>
            <footer className="bg-dark text-inverse mt-3 mobile-footer" >
                <div className="container py-10 py-md-10">
                    <div className="row gy-2 gy-lg-0">
                        <div className="col-md-4 col-lg-4">
                            <div className="widget">
                                <img className="mb-4" src={Logo} alt="" style={{ height: "50px" }} />

                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 mt-5 mb-4">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-1">KONTAKT</h4>
                                <ul className="list-unstyled mb-0">

                                    <li className="pt-md-10 md-height-25" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                                <i className="uil uil-map-marker fs-18"></i>
                                            </div>
                                            <div className="col-lg-2 col-5 pt-md-20 pb-md-10 font-md-14">
                                                Adresse:
                                            </div>
                                            <div className="col-lg-8 col-7 pt-md-20 pb-md-10 font-md-14">
                                                Schaffhauserstrasse 316
                                            </div>
                                        </div>
                                    </li>

                                    <li className="pt-md-10" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                            </div>
                                            <div className="col-lg-2 col-5 pt-md-20 pb-md-10">
                                            </div>
                                            <div className="col-lg-8 col-7 pt-md-20 pb-md-10 font-md-14">
                                                CH-8050 Zürich
                                            </div>
                                        </div>
                                    </li>


                                    <li className="pt-md-10 md-height-25" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                                <i className="uil uil-phone-volume fs-18"></i>
                                            </div>
                                            <div className="col-lg-2 col-5 pt-md-20 pb-md-20 font-md-14">
                                                Telefon:
                                            </div>
                                            <div className="col-lg-8 col-7 pt-md-20 pb-md-20 font-md-14">
                                                044 313 96 40
                                            </div>
                                        </div>
                                    </li>

                                    <li className="pt-md-10 md-height-25" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                                <i className="uil uil-envelope-minus fs-18"></i>
                                            </div>
                                            <div className="col-lg-2 col-5 pt-md-0 pb-md-20 font-md-14">
                                                E-Mail:
                                            </div>
                                            <div className="col-lg-8 col-7 pt-md-0 pb-md-20 font-md-14">
                                                info@multipunkt.ch
                                            </div>
                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 mt-5">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-1">Öffnungszeiten</h4>
                                <ul className="list-unstyled  mb-0">

                                    <li className="pt-md-10 md-height-25" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                                <i className="uil uil-clock-seven fs-18"></i>
                                            </div>
                                            <div className="col-lg-4 col-5 pt-md-20 pb-md-20 font-md-14">
                                                Montag-Freitag:
                                            </div>
                                            <div className="col-lg-7 col-7 pt-md-20 pb-md-20 font-md-14">
                                                09:00–12:00
                                            </div>
                                        </div>
                                    </li>

                                    <li className="pt-md-10 md-height-25" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                            </div>
                                            <div className="col-lg-4 col-5 pt-md-20 pb-md-20">
                                            </div>
                                            <div className="col-lg-7 col-7 pt-md-20 pb-md-20 font-md-14">
                                                13:30–17:30
                                            </div>
                                        </div>
                                    </li>

                                    <li className="pt-md-10 md-height-25" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-1 p-md-0 d-flex justify-content-center d-md-none">
                                                <i className="uil uil-clock-seven fs-18"></i>
                                            </div>
                                            <div className="col-lg-4 col-5 pt-md-20 pb-md-20 font-md-14">
                                                Samstag-Sonntag:
                                            </div>
                                            <div className="col-lg-7 col-7 pt-md-20 pb-md-20 font-md-14">
                                                Geschlossen
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 mt-10 fs-14">
                            <p className="mb-0">© 2023 <a href="https://www.grapix.ch/" target="_blank">Grapix</a>. <br className="d-none d-lg-block" />All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>

            <footer className="bg-dark text-inverse desktop-footer mt-5">
                <div className="container py-13 py-md-15">
                    <div className="row gy-6 gy-lg-0">

                        <div className="col-md-4 col-lg-4">
                            <div className="widget">
                                <img className="mb-4" src={Logo} alt="" style={{ height: "50px" }} />
                                <p className="mb-4">© 2023 <a href="https://www.grapix.ch/" target="_blank">Grapix</a>. All rights reserved.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="widget">
                                <h4 className="widget-title mb-3 text-white">KONTAKT</h4>
                                <ul className="list-unstyled mb-0">

                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                                <i className="uil uil-map-marker fs-18"></i>
                                            </div>
                                            <div style={{ width: "100px" }} className="fs-14">
                                                Adresse:
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                Schaffhauserstrasse 316
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                            </div>
                                            <div style={{ width: "100px" }} className="fs-14">
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                CH-8050 Zürich
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                                <i className="uil uil-phone-volume fs-18"></i>
                                            </div>
                                            <div style={{ width: "100px" }} className="fs-14">
                                                Telefon:
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                044 313 96 40
                                            </div>
                                        </div>
                                    </li>

                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                                <i className="uil uil-envelope-minus fs-18"></i>
                                            </div>
                                            <div style={{ width: "100px" }} className="fs-14">
                                                E-Mail:
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                info@multipunkt.ch
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="widget">
                                <h4 className="widget-title mb-3 text-white">Öffnungszeiten</h4>
                                <ul className="list-unstyled  mb-0">
                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                                <i className="uil uil-clock-seven fs-18"></i>
                                            </div>
                                            <div style={{ width: "200px" }} className="fs-14">
                                                Montag-Freitag:
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                09:00–12:00
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                            </div>
                                            <div style={{ width: "200px" }} className="fs-14">
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                13:30–17:30
                                            </div>
                                        </div>
                                    </li>
                                    <li style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "30px" }}>
                                                <i className="uil uil-clock-seven fs-18"></i>
                                            </div>
                                            <div style={{ width: "200px" }} className="fs-14">
                                                Samstag-Sonntag:
                                            </div>
                                            <div style={{ width: "100%" }} className="fs-14">
                                                Geschlossen
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}