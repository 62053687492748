import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
import axios from "axios";
import { FxeRoot } from "../module/element";

function Contact() {

    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Comment, setComment] = useState("")
    const [Gender, setGender] = useState("")
    const [Message, setMessage] = useState({ status: 0, text: "" })

    const _send_form = () => {
        const control = () => {
            if (User === "") { setMessage({ status: 1, text: "Diese Felder müssen ausgefüllt werden." }); return false }
            if (Email === "") { setMessage({ status: 1, text: "Diese Felder müssen ausgefüllt werden." }); return false }
            if (Phone === "") { setMessage({ status: 1, text: "Diese Felder müssen ausgefüllt werden." }); return false }
            if (Comment === "") { setMessage({ status: 1, text: "Diese Felder müssen ausgefüllt werden." }); return false }
            if (Gender === "") { setMessage({ status: 1, text: "Diese Felder müssen ausgefüllt werden." }); return false }
            return true;
        }



        if (control() === true) {
            const g_email = "info@multipunkt.ch"

            //Info Email 
            axios.post(FxeRoot() + "/api.php", {
                Set: "Email",
                Content: "" +
                    "Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf." +
                    "<br/><br/>" +
                    "Anrede: " + Gender + "<br/>" +
                    "Name und Vorname: " + User + "<br/>" +
                    "Telefon: " + Phone + "<br/>" +
                    "E-Mail: " + Email + "<br/>" +
                    "Bemerkungen: " + Comment + "<br/>",
                Title: "Neue Nachricht",
                Email: g_email,
                User: User,
            })

            //Costumer Info
            var respec = ""
            if (Gender === "Frau") {
                respec = "Sehr geehrte Frau " + User;
            }
            else {
                respec = "Sehr geehrter Herr " + User
            }

            axios.post(FxeRoot() + "/api.php", {
                Set: "Email",
                Content: ("" +
                    "" + respec + "<br/>" +
                    "<br/>" +
                    "Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br/>" +
                    "<br/>" +
                    "Freundlichen Grüsse" +
                    "<br/><br/>" +
                    "Multipunkt Team " +
                    "<br/><br/>" +
                    "Multipunkt GmbH<br/>" +
                    "Schaffhauserstrasse 316 <br/>" +
                    "CH-8050 Zürich<br/><br/>" +
                    "" + g_email + "<br/>" +
                    "044 313 96 40<br/>"),
                Title: "Ihre Nachricht ist bei uns angekommen",
                Email: Email,
                User: User,
            })

            setMessage({ status: 2, text: "Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank!" })

            setUser("")
            setEmail("")
            setPhone("")
            setComment("")
            setGender("")

        }
    }

    return (
        <>
            <Helmet>
                <link rel="icon" href={"../assets/img/favico.ico"} />
                <meta charSet="utf-8" />
                <title>Multipunkt</title>
                <link rel="canonical" href="https://multipunkt.ch/" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" name="description" content="" />
                <meta property="og:description" name="description" content="" />
            </Helmet>
            <section className="wrapper" >
                <div className="container">
                    <div className="row ">
                        <section className="wrapper" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="fs-32 text-gold fxe-olivio-ral-color text-center mt-5 mb-2" style={{ marginBottom: "-5px" }}>Kontaktformular</h2>
                                        <div className="display-7 text-center">Schicken Sie uns Ihren Kommentar.</div>
                                        <div className="display-7 text-center">Gerne bearbeiten wir Ihre Anfrage während der Bürozeiten von Montag – Freitag zwischen 09.00-17.30 Uhr.</div>
                                        <p className="display-7 text-center">Vielen herzlichen Dank!</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="col-xl-10 mx-auto mt-10 mt-lg-8 mb-10 ">
                            <div className="row gy-2 gx-lg-8 gx-xl-12">
                                <div className="col-lg-6">
                                    <div className="card card-body text-center">
                                        <div ><i className="uil uil-phone fs-42"></i></div>
                                        <h2 className="fs-18 mb-3 text-center">Telefon</h2>
                                        <a href="tel:044 313 96 40" className="mt-2">044 313 96 40</a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-body text-center">
                                        <div ><i className="uil uil-envelopes fs-42"></i></div>
                                        <h2 className="fs-18 mb-3 text-center">E-Mail</h2>
                                        <a href="mailto:info@ristorante-olivo.ch" className="mt-2">info@multipunkt.ch</a>
                                    </div>
                                </div>
                                <div className="col-lg-12"></div>
                                <div className="col-lg-8">
                                    <div className="card card-body">
                                        <div className="messages"></div>
                                        <div className="row gx-4">

                                            <div className="col-lg-12">
                                                {
                                                    Message.status === 1 &&
                                                    <div className="alert alert-danger border d-flex align-items-center" style={{ minHeight: "60px" }}>
                                                        <div className="w-50px">
                                                            <i className="uil uil-info-circle fs-32"></i>
                                                        </div>
                                                        <div className="w-100 ms-2">
                                                            {Message.text}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    Message.status === 2 &&
                                                    <div className="alert alert-success border d-flex align-items-center" style={{ minHeight: "60px" }}>
                                                        <div className="w-50px">
                                                            <i className="uil uil-check-circle fs-32"></i>
                                                        </div>
                                                        <div className="w-100 ms-2">
                                                            {Message.text}
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="col-lg-12">
                                                <div class="form-select-wrapper mb-4">
                                                    <div className="form-floating mb-4">
                                                        <select class="form-select" aria-label="Default select example" value={Gender} onChange={(e) => { setGender(e.target.value) }}>
                                                            <option ></option>
                                                            <option value="Frau">Frau</option>
                                                            <option value="Herr">Herr</option>
                                                        </select>
                                                        <label for="textInputExample">Anrede  <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-floating mb-4">
                                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="Jane" required="" value={User} onChange={(e) => { setUser(e.target.value) }} />
                                                    <label htmlFor="form_name">Name und Vorname <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_lastname" type="text" name="surname" className="form-control" placeholder="Doe" required="" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                                    <label htmlFor="form_lastname">E-Mail  <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_email" type="email" name="email" className="form-control" placeholder="jane.doe@example.com" required="" value={Phone} onChange={(e) => { setPhone(e.target.value) }} />
                                                    <label htmlFor="form_email">Telefon <span className="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-floating mb-4">
                                                    <textarea id="form_message" name="message" className="form-control" placeholder="Your message" style={{ height: "150px" }} required="" value={Comment} onChange={(e) => { setComment(e.target.value) }}></textarea>
                                                    <label htmlFor="form_message">Nachricht <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-6">
                                                <button className="btn bg-olivio-danger rounded-pill btn-send mb-3 btn-primary" onClick={() => { _send_form() }}>Senden</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-body p-0">
                                        <div className="d-flex flex-row">
                                            <figure>
                                                <img style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }} />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="card card-body">
                                        <div className="d-flex flex-row">
                                            <div>
                                                <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                                            </div>
                                            <div>
                                                <h5 className="mb-1">Adress</h5>
                                                <address>Schaffhauserstrasse 316 <br />CH-8050 Zürich<br /></address>
                                            </div>

                                        </div>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
                                            </div>
                                            <div>
                                                <h5 className="mb-1">Telefon</h5>
                                                <p><a href="tel:044 534 99 99" className="mt-2">044 313 96 40</a></p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-envelope"></i> </div>
                                            </div>
                                            <div>
                                                <h5 className="mb-1">E-mail</h5>
                                                <p className="mb-0"><a href="mailto:info@ristorante-olivo.ch" className="link-body">
                                                    <span className="__cf_email__">info@multipunkt.ch</span>
                                                </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}
export default Contact;