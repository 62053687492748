import { useEffect, useState } from "react"
import Logo from "../assets/img/colo-colo.png"
import WhiteLogo from "../assets/img/white-colo.png"

export function Header() {

    const [Fixed, setFixed] = useState(0);
    const [Toggle, setToggle] = useState(0)
    const [MobileShow, setMobileShow] = useState(0)


    useEffect(() => {
        const handleScroll = () => { setFixed(window.scrollY); };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    useEffect(() => {
        if (window.location.pathname !== "/kontakt") {
            var navbar = document.querySelector(".navbar");
            if (navbar == null) return;
            const header_height = document.querySelector(".navbar").offsetHeight;
            const shrinked_header_height = 75;
            const sections = document.querySelectorAll(".onepage section");
            sections.forEach(section => {
                section.style.paddingTop = shrinked_header_height + 'px';
                section.style.marginTop = '-' + shrinked_header_height + 'px';
            });
            const first_section = document.querySelector(".onepage section:first-of-type");
            if (first_section != null) {
                first_section.style.paddingTop = header_height + 'px';
                first_section.style.marginTop = '-' + header_height + 'px';
            }
        }
    }, [])

    useEffect(() => {
        if (window.location.pathname !== "/kontakt") {
            let section = document.querySelectorAll('section[id]');
            let navLinks = document.querySelectorAll('.nav-link.scroll');
            window.onscroll = () => {
                section.forEach(sec => {
                    let top = window.scrollY; //returns the number of pixels that the document is currently scrolled vertically.
                    let offset = sec.offsetTop - 0; //returns the distance of the outer border of the current element relative to the inner border of the top of the offsetParent, the closest positioned ancestor element
                    let height = sec.offsetHeight; //returns the height of an element, including vertical padding and borders, as an integer
                    let id = sec.getAttribute('id'); //gets the value of an attribute of an element
                    if (top >= offset && top < offset + height) {
                        navLinks.forEach(links => {
                            links.classList.remove('active');
                            document.querySelector(`.nav-link.scroll[href*=${id}]`).classList.add('active');
                            //[att*=val] Represents an element with the att attribute whose value contains at least one instance of the substring "val". If "val" is the empty string then the selector does not represent anything.
                        });
                    }
                });
            }
        }

    }, [])

    useEffect(() => {
        if (window.location.pathname !== "/kontakt") {
            const links = document.querySelectorAll(".scroll");
            for (const link of links) {
                link.addEventListener("click", clickHandler);
            }

            function clickHandler(e) {
                e.preventDefault();
                this.blur();
                const href = this.getAttribute("href");
                const offsetTop = document.querySelector(href).offsetTop;
                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
            return () => {
                for (const link of links) {
                    link.removeEventListener("click", clickHandler);
                }
            };
        }

    }, []);

    return (
        <>
            <nav className={`navbar navbar-expand-lg center-nav transparent position-absolute navbar-light caret-none navbar-clone fixed navbar-stick`}>
                <div className="container flex-lg-row flex-nowrap align-items-center">
                    <div className="navbar-brand w-100">
                        <a href="/">
                            <img src={Logo} alt="" height={40} />
                        </a>
                    </div>
                    <div className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start d-md-none ${Toggle === 1 ? "show" : ""}`}>
                        <div className="offcanvas-header d-lg-none">
                            <h3 className="text-white fs-30 mb-0">Sandbox</h3>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100 ">
                            <ul className="navbar-nav">
                                <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll active"}`} href={`${window.location.pathname === "/kontakt" ? "/#home" : "#home"}`}>HOME</a></li>
                                <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#uberuns" : "#uberuns"}`}>ÜBER UNS</a></li>
                                <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#steuern" : "#steuern"}`} hidden>STEUERN</a></li>
                                <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#buchhaltung" : "#buchhaltung"}`} hidden>BUCHHALTUNG</a></li>
                                <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#treuhad" : "#treuhad"}`} hidden>TREUHAND</a></li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" onClick={() => { setMobileShow(1) }}>DIENSTLEISTUNGEN</a>
                                    <ul className={`dropdown-menu ${MobileShow === 1 ? "show" : ""}`}>
                                        <li className="nav-item"><a className="dropdown-item" href={`${window.location.pathname === "/kontakt" ? "/#steuern" : "#steuern"}`}>STEUERN</a></li>
                                        <li className="nav-item"><a className="dropdown-item" href={`${window.location.pathname === "/kontakt" ? "/#buchhaltung" : "#buchhaltung"}`}>BUCHHALTUNG</a></li>
                                        <li className="nav-item"><a className="dropdown-item" href={`${window.location.pathname === "/kontakt" ? "/#treuhad" : "#treuhad"}`}>TREUHAND</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "active" : ""}`} href="/kontakt">KONTAKT</a></li>
                            </ul>
                            <div className="offcanvas-footer d-lg-none">
                                <div>
                                    <a href="cdn-cgi/l/email-protection.html#6d0b041f1e1943010c1e192d08000c0401430e0200" className="link-inverse">info@email.com</a>
                                    <br /> 00 (123) 456 78 90 <br />
                                    <nav className="nav social social-white mt-4">
                                        <a href="#"><i className="uil uil-twitter"></i></a>
                                        <a href="#"><i className="uil uil-facebook-f"></i></a>
                                        <a href="#"><i className="uil uil-dribbble"></i></a>
                                        <a href="#"><i className="uil uil-instagram"></i></a>
                                        <a href="#"><i className="uil uil-youtube"></i></a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-other w-100 d-flex ms-auto">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item d-lg-none">
                                <button className="hamburger offcanvas-nav-btn" onClick={() => { setToggle(1) }}>
                                    <span></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


            <div className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start d-lg-none ${Toggle === 1 ? "show" : ""}`}>
                <div className="offcanvas-header d-lg-none w-100">
                    <img src={WhiteLogo} alt="" height={40} />
                    <button type="button" className="btn-close btn-close-white" onClick={() => { setToggle(0) }}></button>
                </div>
                <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100 w-100" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                    <ul className="navbar-nav" style={{ marginLeft: "30px" }}>
                        <li className="nav-item" ><a onClick={() => { setToggle(0); setMobileShow(0) }} style={{ height: "40px" }} className={`nav-link d-flex align-items-center ${window.location.pathname === "/kontakt" ? "" : "scroll active"}`} href={`${window.location.pathname === "/kontakt" ? "/#home" : "#home"}`}>HOME</a></li>
                        <li className="nav-item" ><a onClick={() => { setToggle(0); setMobileShow(0) }} style={{ height: "40px" }} className={`nav-link d-flex align-items-center ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#uberuns" : "#uberuns"}`}   >ÜBER UNS</a></li>
                        <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#steuern" : "#steuern"}`} hidden>STEUERN</a></li>
                        <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#buchhaltung" : "#buchhaltung"}`} hidden>BUCHHALTUNG</a></li>
                        <li className="nav-item"><a className={`nav-link ${window.location.pathname === "/kontakt" ? "" : "scroll"}`} href={`${window.location.pathname === "/kontakt" ? "/#treuhad" : "#treuhad"}`} hidden>TREUHAND</a></li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle  d-flex align-items-center" style={{ height: "40px" }} onClick={() => { MobileShow === 0 ? setMobileShow(1) : setMobileShow(0) }}>DIENSTLEISTUNGEN</a>
                            <ul className={`dropdown-menu ${MobileShow === 1 ? "show" : ""}`} style={{ backgroundColor: "transparent", paddingTop: "5px", paddingBottom: "5px" }}>
                                <li className="nav-item"><a onClick={() => { setToggle(0); setMobileShow(0) }} className="dropdown-item text-white" href={`${window.location.pathname === "/kontakt" ? "/#steuern" : "#steuern"}`}>STEUERN</a></li>
                                <li className="nav-item"><a onClick={() => { setToggle(0); setMobileShow(0) }} className="dropdown-item text-white" href={`${window.location.pathname === "/kontakt" ? "/#buchhaltung" : "#buchhaltung"}`}>BUCHHALTUNG</a></li>
                                <li className="nav-item"><a onClick={() => { setToggle(0); setMobileShow(0) }} className="dropdown-item text-white" href={`${window.location.pathname === "/kontakt" ? "/#treuhad" : "#treuhad"}`}>TREUHAND</a></li>
                            </ul>
                        </li>
                        <li className="nav-item" ><a style={{ height: "40px" }} className={`nav-link  d-flex align-items-center ${window.location.pathname === "/kontakt" ? "active" : ""}`} href="/kontakt">KONTAKT</a></li>
                    </ul>
                    <div className="offcanvas-footer d-lg-none" style={{ marginLeft: "30px" }}>
                        <div>
                            <a className="link-inverse">
                                info@multipunkt.ch
                            </a>
                            <br /> 044 313 96 40<br />
                        </div>
                    </div>
                </div>
            </div>

            {Toggle === 1 ? <div onClick={() => { setToggle(0) }} className={`offcanvas-backdrop fade d-lg-none  ${Toggle === 1 ? "show" : ""}`} style={{ display: `${Toggle === 1 ? "block" : "none"}`, zIndex: 1020 }}></div> : <></>}

        </>
    )
}