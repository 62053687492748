import { Route, Routes } from "react-router-dom";
import Home from "./page/home";
import Contact from "./page/contact";
import { Header } from "./module/header";
import { Footer } from "./module/footer";

function App() {
  return (
    <>
      <Header />
      <div className="d-md-none" style={{ height: "70px" }}></div>
      <div className="d-lg-none" style={{ height: "90px" }}></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kontakt" element={<Contact />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
